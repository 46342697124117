<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general">
           <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
        </el-tab-pane>
        <el-tab-pane label="Pricing" name="pricing">
           <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
        </el-tab-pane>
        <el-tab-pane label="Restrictions" name="restrictions">
           <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
        </el-tab-pane>
        <el-tab-pane label="StopSession" name="stopSession">
           <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import customeTable from '@/components/customeTable'
import jsonData from './jsonData'
import apis from '@/services/index'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    customeTable
  },
  mixins: [mixin],
  data () {
    return {
      custName: {},
      jsonData: jsonData,
      name: 'generalVue',
      activeName: 'general'
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await apis.TariffsBillings.getTariffsDetail(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.tariff
      }
      this.$store.state.app.loading.close()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data) {

    }
  }
}
</script>

<style lang="scss" scope>
.managerUserOrderView {
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
