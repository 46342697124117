// import i18n from '@/i18n/index'

export default {
  general: [{
    prop: 'name'
  }, {
    prop: 'additional_information'
  }],
  pricing: [{
    prop: 'tariff_type'
  }, {
    prop: 'connection_fee'
  }, {
    prop: 'price_per_kwh'
  }, {
    prop: 'duration_fee_time_period'
  }, {
    prop: 'fee_per_time_period'
  }, {
    prop: 'duration_fee_grace_period'
  }, {
    prop: 'idle_fee_per_minute'
  }, {
    prop: 'idle_fee_grace_period'
  }, {
    prop: 'pre_authorize_amount'
  }, {
    prop: 'min_price'
  }],
  restrictions: [{
    prop: 'users_of_cp_owner'
  }, {
    prop: 'users_of_all_roaming_partners'
  }, {
    prop: 'ad_hoc_users'
  }, { prop: 'user_groups' }],
  stopSession: [{
    prop: 'session_max_time'
  }, {
    prop: 'energy_exceeds'
  }, {
    prop: 'stop_when_pre_authorization_amount_falls_below'
  }, {
    prop: 'minimum_balance_required_to_start_session'
  }, {
    prop: 'automatically_stop_when_balance_fall_below'
  }]
}
